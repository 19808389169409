.SummaryTable {
    display: flex;
    flex-direction: column;
}

.SummaryTable .DataTable th,
.SummaryTable .DataTable td:not(:first-child) {
    text-align: right;
}

.SummaryTable .DataTable:first-child {
    margin-right: 20px;
}

.SummaryTable .strong {
    font-weight: bold;
}

@media only screen and (min-width: 512px) {
    .SummaryTable {
        flex-direction: row;
    }
}
