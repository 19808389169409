.FitnessHome .data-row > div:last-child {
    min-width: 220px;
    max-width: 100%;
}

.FitnessHome .CardRow.data-row {
    padding: 0;
}

.FitnessHome .data-row > div:first-child {
    flex-grow: 1;
}

.FitnessHome .ChartCard {
    display: none;
}

@media only screen and (min-width: 512px) {
    .FitnessHome .CardRow.ChartCard {
        display: flex;
        flex-direction: row;
    }
}
