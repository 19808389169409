.AppHeader {
    background-color: var(--color-dark);
    display: flex;
    justify-content: space-between;
    color: var(--color-text-header);
}

.App header.simple {
    justify-content: space-between;
    padding-left: 14px;
}

.App .AppHeader {
    padding: 20px 20px;
}

.App .AppHeader a {
    font-size: 1rem;
}

.App .AppHeader .AppHeaderNavRow a {
    text-align: center;
    color: var(--color-text-secondary);
}

.App .AppHeader a.active {
    text-decoration: underline;
    color: var(--color-text);
}

.App .AppHeader a.home-link {
    margin: 0;
}

.App .AppHeader a {
    color: var(--color-text-header);
    padding-right: 5px;
    text-decoration: none;
}

.App .AppHeader .header-back {
    cursor: pointer;
}

.App .AppHeader .header-menu {
    cursor: pointer;
}

.App .AppHeader .nav-link-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.App .AppHeader .nav-link-title {
    font-size: 12px;
}

.App .AppHeader .AppHeaderNavRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/*
 * On progressive web apps, use a sticky header
 * https://gist.github.com/sconstantinides/221a9ae6bf8a1d2bc02f1e5d5d5ddf61
 */
.App .AppHeader {
    background-color: var(--content-background-panels);
    padding-top: 14px;
    padding-bottom: 14px;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 289;
}

@media (min-width: 512px) {
    .App .AppHeader {
        top: 0;
        border-bottom: 1px solid var(--separator-color);
    }

    .App .AppHeader .AppHeaderNavRow {
        justify-content: flex-start;
    }

    .App .AppHeader .AppHeaderNavRow .nav-link-title {
        font-size: 14px;
        padding: 0 10px;
    }
}

@media (max-width: 512px) {
    .App .AppHeader {
        bottom: 0;
        border-top: 1px solid var(--separator-color);
    }
}

/* PWA */
@media (max-width: 512px) and (display-mode: fullscreen) {
    @supports (-webkit-overflow-scrolling: touch) {
        .App .AppHeader {
            padding-bottom: 30px;
        }
    }
}
