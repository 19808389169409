.BudgetTransactionsReview .FormRow {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.BudgetTransactionsReview .FormRow .form-row-content {
    width: 60%;
}

.BudgetTransactionsReview .form-row-separator {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid var(--separator-color);
}

.BudgetTransactionsReview .title-row {
    display: flex;
    justify-content: space-between;
}

.BudgetTransactionsReview .title-row > div:last-child {
    color: var(--color-text-muted);
}

.BudgetTransactionsReview .rule-section .FormWrapper {
    margin-bottom: 0;
    margin-top: 40px;
}
