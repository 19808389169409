.Progress {
    width: 100%;
    height: 20px;
    border-radius: 5px;
    background-color: var(--separator-color);
}

.Progress .progress-bar {
    height: 20px;
    border-radius: 5px;
    max-width: 100%;
}

.Progress .progress-info {
    background-color: var(--color-info);
}

.Progress .progress-success {
    background-color: var(--color-success);
}

.Progress .progress-danger {
    background-color: var(--color-danger);
}
