:root {
    /* Primary theme colors */
    --color-dark: #07004D;
    --color-info: #2D82B7;
    --color-success: #42E2B8;
    --color-warning: #F3DFBF;
    --color-danger: #D9534F;

    /* Typography */
    --color-text: #16161A;
    --color-text-secondary: #a0aec0;
    --color-text-muted: #72757a;
    --color-text-inverse: #fffffe;
    --color-text-header: #fffffe;
    --text-large: 20px;
    --text-medium: 16px;
    --text-small: 14px;

    /* Spacing */
    /* TODO */

    /* Common */
    --content-background-menu: #2b2c34;

    /* Light Mode */
    --body-background: #ececec;
    --links-color: #2D82B7;
    --button-outline: #72757a;
    --content-background-panels: #fffffe;
    --content-background-forms: #f5f5f5;
    --separator-color: #ececec;
}

/* Dark mode colors */
@media (prefers-color-scheme: dark) {
    :root {
        --body-background: #16161a;
        --color-text: #fffffe;
        --color-text-secondary: #c7cdd8;
        --color-text-muted: #72757a;
        --color-text-inverse: #2b2c34;
        --color-text-header: #fffffe;
        --links-color: #fffffe;
        --button-outline: #d1d1e9;
        --content-background-panels: #2b2c34;
        --content-background-forms: #2b2c34;
        --separator-color: #72757a;
    }
}
