.AutoComplete {
    /*the container must be positioned relative:*/
    position: relative;
    display: inline-block;
    width: 100%;
}

.AutoComplete .suggestions {
    position: absolute;
    border: 1px solid var(--separator-color);
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the suggestions items to be the same width as the container:*/
    top: 42px;
    left: 0;
    right: 0;
}

.AutoComplete .suggestions > div {
      padding: 8px;
      cursor: pointer;
      background-color: var(--content-background-panels);
      border-bottom: 1px solid var(--separator-color);
}

.AutoComplete .suggestions > div:hover {
      /*when hovering an item:*/
      color: var(--text-inverse);
      background-color: var(--color-success);
}

.AutoComplete .suggestions > div.selected {
      /*when navigating through the items using the arrow keys:*/
      background-color: var(--color-success) !important;
      color: #16161a;
}

