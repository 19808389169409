.EstimateBar {
    border-radius: 5px;
    width: 100%;
    background-color: var(--separator-color);
    cursor: pointer;
}

.EstimateBar .inner-bar {
    border-radius: 5px;
    height: 15px;
}

.EstimateBar.danger .inner-bar {
    background-color: red;
}

.EstimateBar.warning .inner-bar {
    background-color: var(--color-danger);
}

.EstimateBar.success .inner-bar {
    background-color: var(--color-success);
}

.CategoryEstimates {
    margin-top: 1.5rem;
}

.EstimateSection {
    font-size: 0.875rem;
}

.EstimateList .Button {
    margin-left: 10px;
    margin-top: 10px;
    border-radius: 100%;
    font-size: 0.675rem;
}

/*
 * EstimateRow
 */

.EstimateRow {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid var(--separator-color);
}

.EstimateRow:hover {
    background-color: var(--content-background-forms);
}

.EstimateRow .estimate-amount {
    text-align: right;
}

/*
 * Unestimated Transactions
 */

.UnestimatedTransactions .subtitle {
  margin-top: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

/*
 * Expanding Details Transition
 */

.openSection-enter {
  opacity: 0;
  max-height: 0;
}

.openSection-enter-active {
  opacity: 1;
  max-height: 300px;
  overflow-y: hidden;
  transition: max-height 200ms ease-in, opacity 300ms ease-in;
}

.openSection-exit {
  opacity: 1;
  max-height: 300px;
  /* This prevents there being a jitter at the end. I hate it though.
  margin-bottom: -51px;
 */
}

.openSection-exit-active {
  opacity: 0;
  max-height: 0;
  overflow-y: hidden;
  transition: max-height 200ms ease-out, opacity 100ms ease-out;
}

