.Login {
    padding-top: 40px;
    display: flex;
    justify-content: center;
    height: calc(100vh - 40px);
    background-color: var(--theme-primary);
    text-align: center;
}

.Login .login-inner {
    padding-top: 10px;
}

.Login .login-auth-form {
    max-width: 400px;
    margin-left: 10px;
    margin-right: 10px;
}

.Login .login-header {
    font-size: 50px;
    color: var(--theme-light);
    margin-bottom: 40px;
}

.Login .button-row .Button {
    font-weight: bold;
}

.Login .button-row a {
    color: rgba(255, 255, 255, 0.9);
}

.Login .Input {
    color: var(--theme-dark);
    background: var(--theme-light);
}

.Login .Input:focus {
    color: var(--theme-dark);
    background: var(--theme-light);
}

@media only screen and (min-width: 512px) {
    .Login .login-inner {
        padding-top: 20vh;
    }

    .Login .button-row a {
        color: rgba(255, 255, 255, 0.5);
    }
}
