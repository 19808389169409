/*
 * FormWrapper
 */

.FormWrapper {
    margin-bottom: 40px;
}

.Forms.FormWrapper label {
    font-size: 0.875rem;
    text-transform: uppercase;
    color: var(--color-text-secondary);
    display:inline-block;
    margin-bottom: 10px;
}

.Forms.FormWrapper .subtext {
    margin-top: 5px;
    font-size: 0.875rem;
    color: var(--color-text-secondary);
    font-style: italic;
}

.Forms.FormWrapper .error-message {
    margin-top: 5px;
    font-size: 0.875rem;
    color: var(--color-danger);
    font-style: italic;
}

.Forms.FormWrapper.form-error .Input,
.Forms.FormWrapper.form-error .TextArea,
.Forms.FormWrapper.form-error .Select {
    border-bottom: 2px solid var(--color-danger);
}


/* TODO: Make Input/TextArea/Select attributes common */

/*
 * Common
 */

.Forms .Input,
.Forms .TextArea,
.Forms .Select {
    color: var(--text-primary);
    background-color: var(--content-background-forms);
    outline: none;
    appearance: none;
    line-height: 1.25;
    font-size: 16px;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
    border-bottom: 2px solid var(--color-info);
    width: calc(100% - 20px);
    padding: 10px 10px;
    font-family: 'Roboto script=all rev=1', sans-serif;
}

.Forms .Input:focus,
.Forms .TextArea:focus,
.Forms .Select:focus {
    background-color: var(--body-background);
    border-bottom: 2px solid var(--color-success);
}

/*
 * Input
 */

.Input:focus::placeholder {
    color: transparent;
}

.FileInput[type="file"] {
    visibility: hidden;
    height: 0;
    max-height: 0;
    margin-top: 10px;
}

/*
 * TextArea
 */
.Forms .TextArea {
    height: 2rem;
    resize: vertical;
    transition: height 0.2s ease-out;
    -moz-transition: height 0.2s ease-out;
    -webkit-transition: height 0.2s ease-out;
}

.Forms .TextArea.textarea-large {
    height: 8.5rem;
}

.TextArea::-webkit-input-placeholder {
    font-family: 'Roboto script=all rev=1', sans-serif;
    color: var(--color-text-secondary);
}

.TextArea:-ms-input-placeholder {
    font-family: 'Roboto script=all rev=1', sans-serif;
    color: var(--color-text-secondary);
}

.TextArea:-moz-placeholder {
    font-family: 'Roboto script=all rev=1', sans-serif;
    color: var(--color-text-secondary);
}

.TextArea::-moz-placeholder {
    font-family: 'Roboto script=all rev=1', sans-serif;
    color: var(--color-text-secondary);
}

.TextArea:focus::placeholder {
  color: transparent;
}

/*
 * Select
 */

.Forms.FormWrapper .Select {
    width: 100%;
}

/*
 * Button
 */

.Button {
    appearance: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 0.875rem;
}

a.Button {
    text-decoration: none;
}

.Button.small {
    padding: 5px 8px;
}

.Button.button-link {
    background-color: rgba(0, 0, 0, 0);
    color: var(--links-color);
    border: none;
    text-decoration: underline;
}

.Button.button-outline {
    color: var(--button-outline);
    border: 1px solid var(--button-outline);
    background-color: var(--content-background-panels);
}

.Button.button-outline:hover {
    color: var(--color-text-inverse);
    background-color: var(--button-outline);
}

.Button.button-primary {
    color: var(--color-text-inverse);
    border: 1px solid var(--color-info);
    background-color: var(--color-info);
}

.Button.button-primary:focus,
.Button.button-primary:hover {
    background-color: var(--color-success);
    border: 1px solid var(--color-success);
}

.Button.button-primary-outline {
    color: var(--color-text);
    border: 1px solid var(--color-info);
    background-color: var(--body-background);
}

.Button.button-primary-outline:focus,
.Button.button-primary-outline:hover {
    color: var(--color-text-inverse);
    background-color: var(--color-info-dark);
}

.Button.button-danger {
    color: var(--color-text-inverse);
    border: 1px solid var(--color-danger);
    background-color: var(--color-danger);
}

.Button.button-danger:focus,
.Button.button-danger:hover {
    /* TODO
    background-color: var(--theme-danger-secondary);
    border: 1px solid var(--theme-danger-secondary);
    */
}

.ButtonRow {
    display: flex;
    justify-content: space-between;
}

.CheckBox.selected {
    color: var(--color-success);
}
