@media only screen and (max-width: 512px) {
    .ListDetails {
        background-color: var(--body-background);
    }

    .ListDetails .ListItem .Input {
        background-color: var(--body-background);
    }

    .ListDetails .ListItems {
    }
}


.ListDetails .ListItem {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid var(--separator-color);
}

.ListDetails .ListItem.checked {
    opacity: 0.2;
}

.ListDetails .ListItem .item-menu,
.ListDetails .ListItem .check-box {
    padding: 15px;
    cursor: pointer;
}

.ListDetails .ListItem .item-name {
    flex-grow: 1;
}

.ListDetails .ListItem .FormWrapper {
    margin-bottom: 0;
    width: 100%;
}

.ListDetails .ListItem .Input {
    border-bottom: none;
}

.ListDetails .new-item {
    padding-top: 10px;
}

.ListDetails .new-item .FormWrapper {
    margin-bottom: 18px;
}

.ListDetails .ListItems .ListItemGroup {
    margin-top: 20px;
}
