.Meters {
    display: flex;
    flex-direction: column;
}

.Meters .ReadingsChart .pointer {
    cursor: pointer;
}

.Meters .ReadingsParams > .FormWrapper {
    margin-left: 20px;
    margin-bottom: 15px;
}

.Meters .ReadingsPresets {
    align-items: flex-start;
}
.Meters .ReadingsPresets > a {
    margin-right: 20px;
}

.Meters .ChartCard {
    overflow-y: scroll;
    max-width: 100%;
    direction: rtl;
}

.ReadingsParams {
    justify-content: flex-end;
}

@media only screen and (max-width: 512px) {
    .ReadingsParams {
        justify-content: space-between;
    }
    .Meters .ReadingsParams > .FormWrapper:first-child {
        margin-left: 0;
    }
}
