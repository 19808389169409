.Page {
    padding: 20px;
}

.Row {
    display: flex;
    justify-content: space-between;
}

.Column {
    display: flex;
    flex-direction: column;
}

.Column .Card {
    margin-bottom: 20px;
}

.CenterCard,
.Card {
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    background: var(--content-background-panels);
}

.PageTitle {
    font-size: 1.25rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 12px;
}

.PageTitleRow {
    align-items: center;
}

@media only screen and (max-width: 512px) {
    .Card {
        background-color: var(--body-background);
        padding: 0;
        padding-bottom: 20px;
        border-radius: 0;
    }

    .Card:not(:last-child) {
        border-bottom: 1px solid var(--separator-color);
    }
}

.CenterCard {
    max-width: 600px;
    margin: 20px auto;
}

.SectionTitle {
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 1rem;
    color: var(--text-secondary);
}

.Description {
    padding: 20px;
    background-color: var(--theme-tertiary);
    color: var(--theme-dark);
    line-height: 1.5em;
}

.Placeholder {
    color: var(--text-secondary);
    text-align: center;
}

.CardRow {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
}

@media only screen and (min-width: 512px) {
    .PageTitle {
        font-size: 1.5rem;
        margin-top: 0;
    }
    .CardRow {
        flex-direction: row;
        align-items: flex-start;
    }

    .CardRow > *:not(:first-child) {
        margin-left: 20px;
    }
}

.SidePanelList {
    display: flex;
}

.SidePanelList > .left {
    max-width: 512px;
    margin-left: auto;
    margin-right: auto;
}

.SidePanelList > .right {
    display: none;
}

@media only screen and (max-width: 512px) {
    .SidePanelList > .left {
        width: 100%;
    }
}

@media only screen and (min-width: 512px) {
    .SidePanelList > .left {
        min-width: 512px;
    }
}

@media only screen and (min-width: 1024px) {
    .SidePanelList > .left {
        margin-right: 20px;
        max-height: calc(100vh - 90px);
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .SidePanelList > .right {
        display: block;
        flex-grow: 1;
        max-height: calc(100vh - 90px);
        overflow-y: scroll;
    }
}
