.BudgetDetails {
    padding: 20px;
}

.BudgetDetails .CardRow {
    align-items: stretch;
}

.BudgetDetails .TitleCard > *:not(:last-child),
.BudgetDetails .TitleCard .Column > *:not(:last-child) {
    margin-bottom: 15px;
}

.BudgetDetails .TitleCard .Column {
    align-items: flex-start;
}

.BudgetDetails .TransactionSection .DataTable {
    font-size: 0.75rem;
}

.BudgetDetails .TransactionSection .DataTable tr {
    cursor: pointer;
}

.BudgetDetails .TransactionSection .DataTable .muted {
    background-color: var(--content-background-forms);
}

.BudgetDetails .TransactionSection tr td:last-child {
    text-align: right;
}

.BudgetDetails .TransactionSection tr td:nth-child(2) {
    padding: 0 5px;
}

@media only screen and (min-width: 512px) {
    .BudgetDetails .row-column {
        width: 50%;
    }

    .BudgetDetails .TitleCard {
        min-width: 300px;
    }
}

.BudgetDetails .SectionTitle.subsection {
    font-size: 0.875rem;
}

.BudgetDetails .Total {
    margin-bottom: 5px;
    font-size: 0.875rem;
    color: var(--color-text-muted);
}
