.Menu .menu-content {
    padding-top: 60px;
    height: calc(100% - 40px);
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: var(--content-background-menu);
    overflow-x: hidden;
    transition: 0.5s;
}

.Menu .menu-inner {
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    white-space: pre;
    font-size: 1rem;
    line-height: 2rem;
}

.Menu .menu-closed {
    width: 0;
}

.Menu .menu-open {
    width: 240px;
    border-left: 1px solid var(--separator-color);
}

.Menu .menu-inner .menu-link,
.Menu .menu-inner a {
    padding: 10px 0;
    cursor: pointer;
}

.Menu .menu-inner .menu-link:hover,
.Menu .menu-inner a:hover {
    color: var(--theme-secondary);
}

.Menu .menu-close-button {
    text-align: right;
    padding: 0 20px 10px 20px;
    cursor: pointer;
}

.Menu svg {
    cursor: pointer;
}

.Menu a.bordered {
    border-top: 1px solid var(--separator-color);
}

.Menu .hamburger {
    position: relative;
    font-size: 1.25rem;
}

.Menu .profile-link {
    display: flex;
}
