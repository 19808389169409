.FitnessRoutine .ready {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.FitnessRoutine {
    text-align: center;
}

.FitnessRoutine .ready .DataTable {
    width: 50%;
    margin: 0 auto 40px auto;
}

.FitnessRoutine .ready,
.FitnessRoutine .complete {
    margin-top: 20px;
}

.FitnessRoutine .complete {
    margin-bottom: 30px;
    font-size: 48px;
}

.RunningMode .move-title {
    margin-top: 50px;
    font-size: 55px;
    font-weight: bold;
}

.RunningMode #timer {
    margin-top: 30px;
    font-size: 120px;
    font-weight: bold;
    margin-bottom: 80px;
}

.RunningMode.paused {
    /* This seems to get munged on build somehow */
    opacity: 50%;
}

.RunningMode .cooldown {
    color: var(--color-info);
}

.RunningMode .up-next {
    font-size: 24px;
}

.RunningMode .remaining {
    margin-top: 20px;
}
