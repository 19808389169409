.BudgetTransactionsList {
}

.BudgetTransactionsList .DataTable {
    font-size: 0.875rem;
}

.BudgetTransactionsList .DataTable tr > td:nth-child(4) {
    padding-right: 20px;
    text-align: right;
}

.BudgetTransactionsList .controls {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.BudgetTransactionsList .controls .FormWrapper {
    margin-left: 20px;
    margin-bottom: 15px;
}
