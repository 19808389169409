.DataTable {
    border-collapse: collapse;
    width: 100%;
}

.DataTable thead,
.DataTable tbody {
    color: var(--color-text-secondary);
}

.DataTable th,
.DataTable td {
    padding: 10px 0;
}

.DataTable thead tr {
    border-bottom: 2px solid var(--separator-color);
}

.DataTable tbody tr {
    border-bottom: 1px solid var(--separator-color);
}

.DataTable th {
    text-align: left;
}

.DataTable td svg {
    float: right;
    cursor: pointer;
}
