body {
  margin: 0;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-text);
  background-color: var(--body-background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.muted {
    color: var(--color-text-muted);
}

a,
a:visited {
    color: var(--links-color);
}

/* Matches larger screens */
@media only screen and (min-width: 512px) {
    .mobile-show {
        display: none;
        visibility: hidden;
    }

    .AppContent {
        padding-top: 60px;
    }
}

/* Matches mobile */
@media only screen and (max-width: 512px) {
    .mobile-hide {
        display: none !important;
    }

    .AppContent {
        padding-bottom: 50px;
    }
}

/* For iOS device PWAs */
@media (max-width: 512px) and (display-mode: fullscreen) {
    @supports (-webkit-overflow-scrolling: touch) {
        /* Make space for upper information bar */
        .AppContent {
            margin-top: 30px;
        }
    }
}
