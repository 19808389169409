.Loading {
    width: 100%;
}

.Loading .dots {
    margin: 30px auto;
    width: 3.5em;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}

.Loading .dots > div {
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
    background-color: var(--color-info);
    animation: fade 0.8s ease-in-out alternate infinite;
}

.Loading .dots > div:nth-of-type(1) {
    animation-delay: -0.4s;
}

.Loading .dots > div:nth-of-type(2) {
    animation-delay: -0.2s;
}

@keyframes fade {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
